<template>
  <drawer-form
    ref="drawerForm"
    title="新增设备授权"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='产品' >
      <a-input :value="paras.productName" readonly disabled/>
    </a-form-model-item>

    <a-form-model-item label='批量导入'>
      <div style="margin-bottom: 10px;display: flex;align-items: center">
        <csv-import @onImport="importCsv"></csv-import>
        <div style="margin-left: 20px;color: #0084FF;cursor: pointer" @click="clickDownload">下载导入模板</div>
      </div>
    </a-form-model-item>



    <a-form-model-item label='设备MAC' prop="list">
      <a-textarea
        placeholder="可输入多个MAC，每行一个"
        :auto-size="{ minRows: 10 }"
        v-model="model.list"
      />
    </a-form-model-item>


    <!--    授权结果对话框-->
    <a-modal v-model="resultVisible" title="授权结果" @cancel="hide" @ok="hide">
      <div>授权成功设备数 {{grantResult.successList.length}}</div>
      <div>授权失败设备数 {{grantResult.failList.length}}</div>
      <div v-if="grantResult.failList.length>0">以下设备授权失败：{{grantResult.failList.join(",")}}</div>

    </a-modal>


  </drawer-form>

</template>
<script>

  import Papa from 'papaparse'

  export default {
    data() {
      return {
        paras:{
          vendorId:"",
          productKey:"",
          productName:"",
        },
        model: {
          list:''
        },
        rules: {
          list: [
            {required: true, message: '必填项'},
          ],
        },
        loading: false,

        resultVisible:false,
        grantResult:{successList:[],failList:[]}
      }
    },

    methods: {
      show(paras,type) {
        this.paras=paras
        this.loading=false
        this.model.list=""
        this.$refs.drawerForm.show()
      },

      hide() {
        this.resultVisible=false
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        this.loading=true
        let arr = this.model.list.split("\n")
        let ids = arr.join(",")
        this.loading = true

        this.paras.productKey = "WATER_MONITOR"

        this.$post('vendor/' + this.paras.vendorId + '/device/'+ids,{productKey:this.paras.productKey}).then(suc => {
          this.loading = false
          this.$emit('success')
          // this.hide()

          this.resultVisible=true
          this.grantResult = suc.data

        }).catch(err => {
          this.loading = false
        })

      },
      add() {
        this.loading = true
        this.$post('menu', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      edit() {
        this.loading = true
        this.$put('menu', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      clickDownload(){
        window.open("./static/import.csv")
      },

      importCsv(data){
        let arr = []
        for(let i=1;i<data.length;i++){
          if(data[i] && data[i].length>0 && data[i][0]){
            arr.push(data[i][0])
          }
        }
        this.model.list = arr.join("\r\n")
      }

    }
  }
</script>
